import { useState, useEffect } from "react";
import { Flex, Text, Image } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import a1 from "./a1.png"
import a2 from "./a2.jpg"



export default function About() {
    const history = useHistory()
    return (
        <Flex w="100%" direction="column">
            <Flex w="100%" align="center" px={["0", "8%"]} py={["0", "12"]}>
                <Flex w="100%" py="5" px={["3", "5"]} bgColor="#000" backgroundImage={a1} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center" borderRadius={["0", "8px"]} justify="flex-end">
                    <Flex w={["100%", "70%"]} direction="column" px={["6", "12"]} bg={["rgba(0,0,0,.61)", "rgba(0,0,0,.61)"]} backdropFilter={["blur(1px)", "blur(5px)"]} mt={["12", "100px"]} py={["8", "12"]} color="#fff" borderRadius="8px">
                        <Text fontSize={["26px", "40px"]} data-aos="fade-down" fontWeight="700">About<Text as="span" fontWeight="300"> Us</Text></Text>
                        <Text mt="3" data-aos="fade-up">Your No.1 stop for any gift card purchase and validation - swift response time, top-knotch security and trusted by over 5,000 users daily.</Text>

                        <Text mt="8" data-aos="fade-up" fontSize={["13px", "15px"]}>We offer a platform where users can purchase digital gift cards from all over the world. We are dedicated to making gift giving easier, more convenient, and more personal. With the ability to purchase digital gift cards from a wide range of retailers and brands, both domestically and internationally, you can easily find the perfect gift for any occasion.</Text>

                        
                        <Flex mt="10" data-aos="fade-up" justify={["space-between", "flex-start"]}>
                            <Flex bg="#fff" border="2px solid #fff" color="#000" borderRadius="30px" padding={["12px 28px", "15px 40px"]} fontSize={["12px", "15px"]} fontWeight="600" cursor="pointer" _hover={{ transform: "translate(4px, 0px)", letterSpacing: "1px" }} transition="200ms ease-in-out" align="center" justify="center" onClick={() => {
                                history.push("/")
                                setTimeout(function() {
                                    const element = document.getElementById('pvArea')
                                    return window.scrollTo({
                                    top: element ? element.offsetTop - 50 : 0,
                                    behavior: 'smooth',
                                    })
                                }, 100)
                            }}><Text fontSize="16px" mr="1"><i className="mdi mdi-currency-usd"></i></Text> Buy Card</Flex>
                            
                            <Flex ml={["3", "4"]} color="#fff" border="2px solid #fff" borderRadius="30px" padding={["12px 28px", "15px 40px"]} fontSize={["12px", "15px"]} fontWeight="600" cursor="pointer" _hover={{ transform: "translate(4px, 0px)", letterSpacing: "1px" }} transition="200ms ease-in-out" align="center"  onClick={() => {
                                history.push("/")
                                setTimeout(function() {
                                    const element = document.getElementById('pvArea')
                                    return window.scrollTo({
                                    top: element ? element.offsetTop - 50 : 0,
                                    behavior: 'smooth',
                                    })
                                }, 100)
                            }}><Text fontSize="16px" mr="2"><i className="mdi mdi-shopping"></i></Text> Validate Card</Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex px={["8", "20%"]} align="center" bg="rgb(248,248,250)" justify="space-between" fontSize={["30px", "80px" ]} fontWeight="700" py={["60px", "80px"]}>
                <Text textAlign="center" data-aos="fade-up">20K+<Text fontSize={["10px", "15px"]} fontWeight="400" textAlign="center">Daily Transactions</Text></Text>
                <Text textAlign="center" data-aos="fade-down">12K+<Text fontSize={["10px", "15px"]}fontWeight="400" textAlign="center">Daily Validations</Text></Text>
                <Text textAlign="center" data-aos="fade-up">8K+<Text fontSize={["10px", "15px"]} fontWeight="400" textAlign="center">Digital Card Purchase</Text></Text>
            </Flex>

            <Flex direction="column" px={["8", "20%"]} py={["60px", "80px"]} align="center" borderBottom="1px solid rgb(202, 202, 205)">
                <Flex align="center" mb="60px" data-aos="fade-down">
                    <Text fontSize={["60px", "100px"]} fontWeight="500">4.9</Text>
                    <Flex direction="column" ml="4">
                        <Text color="#ffd200" fontSize={["22px", "30px"]}><i className="mdi mdi-star" style={{ marginRight: "10px"}}></i><i className="mdi mdi-star" style={{ marginRight: "10px"}}></i><i className="mdi mdi-star" style={{ marginRight: "10px"}}></i><i className="mdi mdi-star" style={{ marginRight: "10px"}}></i><i className="mdi mdi-star" style={{ marginRight: "10px"}}></i></Text>
                        <Text mt="5" fontSize={["13px", "15px"]}>Based on 4k+ Reviews</Text>
                    </Flex>
                </Flex>
                <Flex w="151px" h="150px" backgroundImage={a2} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center" borderRadius="100%" mb="12"></Flex>
                <Text fontSize={["26px", "40px"]} data-aos="fade-down" fontWeight="700">Why are<Text as="span" fontWeight="300"> We here?</Text></Text>
                <Text mt="5" data-aos="fade-up" fontSize={["13px", "14px"]} textAlign="center">e-Cardly user-friendly interface allows you to easily browse and purchase digital gift cards with just a few clicks. We understand the importance of convenience and security, which is why we offer a safe and secure payment system for all transactions.We believe that gift giving should be personal and thoughtful, and with the option to send a digital gift card directly to your recipient's email inbox, you can make sure your gift arrives exactly when and where it's needed.</Text>

                <Text mt="5" data-aos="fade-up" fontSize={["13px", "14px"]} textAlign="center">At e-Cardly, we are constantly expanding our offerings and adding new retailers and brands to our platform. Our goal is to make sure that you have the largest possible selection of gift cards to choose from, so you can find the perfect gift every time.

                Thank you for choosing e-Cardly for your digital gift card needs. We look forward to helping you make gift giving easier and more personal</Text>
            </Flex>
        </Flex>
    )
}