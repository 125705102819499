import { Flex, Image, Text, Spinner, } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import logo from "./logo.png"
import { useHistory, useLocation } from "react-router-dom"
import Toast from "./toast"

export default function Footer() {
    const location = useLocation()
    const history = useHistory()
    const [loading, setLoading] = useState(false)

    return (
        <>
            {
                location.pathname.includes("contact") ?
                <></>
                :
                <Flex w="100%" bg="#fff" direction="column" px={["6", "8%"]} pt={["50px", "80px"]} pb={["30px", "50px"]}>
                    <Flex w="100%" justify="space-between" direction={["column", "row"]}>
                        <Flex w={["100%", "38%"]} direction="column">
                            <Flex align="center" mb="5">
                                <Image src={logo} h="100px" />
                                <Text fontSize="20px" ml="3"><Text fontWeight="700" as="span">e-</Text>Cardly</Text>
                            </Flex>
                            <Text fontSize={["11px", "13px"]} mb="4">Purchase digital cards with instant email delivery. we email all kinds of cards internationally. We accept paypal and credit cards mode of payment. We are available 24/7.</Text>

                            <input id="txt1" style={{ width: "100%", border: "1px solid #000", padding: "14px 20px", borderRadius: "30px" }} placeholder="Enter your email to subscribe to our news letter" />
                            
                            <Flex mt="4"><Flex bg="#000" border="2px solid #000" color="#fff" borderRadius="30px" padding="15px 40px" fontSize={["13px", "15px"]} fontWeight="600" cursor="pointer" _hover={{ transform: "translate(4px, 0px)", letterSpacing: "1px" }} transition="200ms ease-in-out" align="center" justify="center" onClick={() => {
                                var a = document.getElementById("txt1")
                                if(a.value !== "") {
                                    setLoading(true)
                                    setTimeout(function() {
                                        setLoading(false)
                                        a.value = ""
                                        Toast("You have been added to our newsletter!", "success")
                                    }, 2000)
                                }
                                else {
                                    Toast("Enter your email", "error")
                                }
                            }}>{loading ? <Spinner /> : <><Text fontSize="16px" mr="2"><i className="mdi mdi-email"></i></Text> Subscribe</>}</Flex>
                            </Flex>
                        </Flex>

                        <Text display={["block", "none"]} h="1px" bg="rgb(202, 202, 205)" mt="8"></Text>


                        <Flex w="auto" direction="column" mt={["5", "0"]}>
                            <Text fontSize="16px" mb="5" fontWeight="600">Explore</Text>
                            {["About", "Contact", "FAQ's", "Privacy Policy"].map((item, index) => (
                                <Text fontSize={["13px", "14px"]} cursor="pointer" color="rgb(102, 112, 133)" mb="5" key={index} _hover={{ color: "#000" }} transition="200ms ease-in-out" onClick={() => {
                                    history.push(index === 0 ? "/about" : index === 1 ? "/contact" : "/")
                                    if(index === 2) {     
                                        setTimeout(function() {
                                            const element = document.getElementById('fArea')
                                            return window.scrollTo({
                                            top: element ? element.offsetTop - 50 : 0,
                                            behavior: 'smooth',
                                            })
                                        }, 100)
                                    }
                                }}>{item}</Text>
                            ))}
                        </Flex>


                        <Flex w="auto" mt={["5", "0"]} direction="column">
                            <Text fontSize="16px" mb="5" fontWeight="600">Actions</Text>
                            {["Buy Card", "Validate Card"].map((item, index) => (
                                <Text fontSize={["13px", "14px"]} cursor="pointer" color="rgb(102, 112, 133)" mb="5" key={index} _hover={{ color: "#000" }} transition="200ms ease-in-out"  onClick={() =>  {
                                    history.push("/")
                                    setTimeout(function() {
                                        const element = document.getElementById('pvArea')
                                        return window.scrollTo({
                                        top: element ? element.offsetTop - 50 : 0,
                                        behavior: 'smooth',
                                        })
                                    }, 100)
                                }}>{item}</Text>
                            ))}
                        </Flex>


                        <Flex w="auto" direction="column" mt={["5", "0"]}>
                            <Flex align="center" mb="8">
                                <Flex fontSize="20px" justify="center" align="center" px="5" w="49px" h="48px" mr={["12", "8"]} bg="#1DA1F2" color="#fff" borderRadius="30px" cursor="pointer" transition="150ms ease-in-out" _hover={{ transform: "translate(0, -4px)" }} fontWeight="600" onClick={() => window.open("https://twitter.com")}>
                                    <Text><i className="mdi mdi-twitter"></i></Text>
                                </Flex>

                                <Flex fontSize="20px" justify="center" align="center" px="5" w="49px" mr={["12", "8"]} h="48px" bg="#25D366" color="#fff" borderRadius="30px" cursor="pointer" transition="150ms ease-in-out" _hover={{ transform: "translate(0, -4px)" }} fontWeight="600" onClick={() => window.open("https://whatsapp.com")}>
                                    <Text><i className="mdi mdi-whatsapp"></i></Text>
                                </Flex>

                                <Flex fontSize="20px" justify="center" align="center" px="5" w="49px" h="48px" bg="#4267B2" color="#fff" borderRadius="30px" cursor="pointer" transition="150ms ease-in-out" _hover={{ transform: "translate(0, -4px)" }} fontWeight="600" onClick={() => window.open("https://facebook.com")}>
                                    <Text><i className="mdi mdi-facebook"></i></Text>
                                </Flex>
                               
                            </Flex>
                            
                            
                            <Text fontSize="16px" mb="5" fontWeight="600">Email</Text>
                            <Text fontSize={["13px", "14px"]} cursor="pointer" color="rgb(102, 112, 133)" mb="5" _hover={{ color: "#000" }} transition="200ms ease-in-out" onClick={() => window.open("mailto: info@e-cardly.com", "_SELF")}>info@e-Cardly.com</Text>

                        </Flex>
                    </Flex>



                    <Flex color="rgb(102, 112, 133)" w="100%" justify="space-between" borderTop="1px solid rgb(202, 202, 205)" mt="8" pt="12" align="center" fontSize={["13px", "14px"]}>
                        <Text>&copy; 2023 <Text as="span" ml="2">e-Cardly</Text></Text>
                        <Text>
                            <Text display={["none", "inline"]} color="rgb(102, 112, 133)" cursor="pointer" _hover={{ color: "#000" }} transition="200ms ease-in-out">Terms &amp; Conditions</Text>
                            <Text ml="8" as="span" color="rgb(102, 112, 133)" cursor="pointer" _hover={{ color: "#000" }} transition="200ms ease-in-out">All rights reserved.</Text>
                        </Text>
                    </Flex>
                </Flex>
            }
        </>
    )
}