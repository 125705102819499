import { createStandaloneToast } from "@chakra-ui/react"
import { Text } from "@chakra-ui/react";

function Toast(message, type) {
  const toast = createStandaloneToast()
  return toast({
      title: <Text fontSize="13px" fontWeight="700">e-Cardly AI</Text>,
      position: "top",
      description: <Text fontWeight="500" fontSize="14px">{message}</Text>,
      status: type,
      duration: 4000,
      isClosable: false,
    })
}

export default Toast;